import request from '@/utils/request'
import storage from 'store'
import { isWechat } from '@/utils/common'
import { USER_INFO } from '@/store/mutation-types'

// 登录
export function login(parameter) {
  return request({
    url: '/api/client/member/authenticate',
    method: 'post',
    data: parameter,
  })
}

// sso登录
export function ssoLogin(data) {
  return request({
    url: '/api/client/member/authenticate/yd',
    method: 'post',
    data
  })
}

// 发送短信验证码
export function sendCode(params) {
  return request({
    url: '/api/client/member/phone-code',
    method: 'get',
    params,
  })
}

// 测试登录信息
export function getTestUser() {
  return request({
    url: '/api/client/member/test',
    method: 'get',
  })
}

// 当前会员登录信息
export function getMemberMe() {
  const params = {}
  if (isWechat()) {
    params.openid = storage.get('openid')
  }
  return request({
    url: '/api/client/member/me',
    method: 'get',
    params
  })
}

// 校验短信验证码
export function verifyCode(params) {
  return request({
    url: '/api/client/getKey',
    method: 'get',
    params,
  })
}

/**
 * 用户等级配置
 * @returns
 */
export function selectUserLevels() {
  return request({
    url: '/api/client/member/level-configs?projectId=3',
    method: 'get'
  })
}

export function getMemberConfigContent(params) {
  return request({
    url: '/api/client/member/config-content',
    method: 'get',
    params
  })
}

export function readGuide() {
  return request({
    url: `/api/client/member/mark-user-notice-read`,
    method: 'get'
  })
}

export function recordLog(data) {
  const user = storage.get(USER_INFO)
  const body = {
    pagePath: window.location.href,
    memberId: user ? user.id : null,
    ...(data || {})
  }
  return request({
    url: '/api/open/error-report',
    method: 'post',
    data: body
  })
}

export function getCmccTokenConfig() {
  return request({
    url: `/api/client/cmcc/token/body`,
    method: 'get'
  })
}

export function cmccVerify(data) {
  return request({
    url: '/api/client/cmcc/token/verify',
    method: 'post',
    data
  })
}

export function reportCmccLog(data) {
  return request({
    url: '/api/client/cmcc/token/callback',
    method: 'post',
    data
  })
}

export function getRenewNoticeFirstMonth() {
  return request({
    url: `/api/client/member/one-day-renew-notice`,
    method: 'get'
  })
}

export function readRenewNoticeFirstMonth() {
  return request({
    url: '/api/client/member/one-day-renew-notice',
    method: 'post'
  })
}